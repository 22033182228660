import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar, Button, Stack, Modal } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import EventNoteIcon from "@mui/icons-material/EventNote";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import RepeatIcon from "@mui/icons-material/Repeat";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { meetingsStore, utilityStore } from "@state/store";

import InviteStatusSelector from "@components/MeetingComponents/SingleMeetingComponents/InviteStatusSelector";
import JoinMeetingButton from "@components/MeetingComponents/MeetingStateViewComponents/DailyCard/Components/JoinMeetingButton";
import DialogDelete from "@components/DialogDelete";

import RepeatMeetingString from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/RepeatMeetingString";
import { getCurrentUserInfo } from "@cognito";
import {
  VTMeetings,
  VTInvite,
  CognitoUser,
  OtherUsers,
  VTInviteObject,
} from "../../types";
// RRULE LIBRARY
import { RRule, RRuleSet, rrulestr } from "rrule";

//TIME LIBRARY
import { fromUnixTime, format } from "date-fns";

//REQUESTS
import { getMeeting, deleteMeeting, updateMeeting } from "@requests/Meetings";
import { MonitorHeartSharp } from "@mui/icons-material";

//UTILITIES
import { getOwnerInitials } from "@utilities/getOwnerInitials";

export type VTMeetingDetailsProps = {
  setSnackBarIsOpenForDeleteMeeting: (value: boolean) => void;
  editMeetingModalIsOpen: boolean;
  setEditMeetingModalIsOpen: (editMeetingModalIsOpen: boolean) => void;
  meetingOverViewModalIsOpen: boolean;
  setMeetingOverViewModalIsOpen: (meetingOverViewModalIsOpen: boolean) => void;
  setSaveMeetingIsClicked: (value: boolean) => void;
};

const MeetingOverview = ({
  editMeetingModalIsOpen,
  meetingOverViewModalIsOpen,
  setMeetingOverViewModalIsOpen,
  setEditMeetingModalIsOpen,
  setSnackBarIsOpenForDeleteMeeting,
}: VTMeetingDetailsProps) => {
  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////
  /////////             States
  /////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  const [needsRefresh, setNeedsRefresh] = useState<boolean>(false);
  const [meetingIsLoaded, setMeetingIsLoaded] = useState<boolean>(false);
  const [inviteId, setInviteId] = useState<string>("");

  const [userStatus, setUserStatus] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [meeting, setMeeting] = useState<VTMeetings>({} as VTMeetings);
  const [isOutlookGenerated, setIsOutlookGenerated] = useState<boolean>(false);

  const [beginningTime, setBeginningTime] = useState<string>("");
  const [endingTime, setEndingTime] = useState<string>("");
  const [formattedDate, setFormattedDate] = useState<string>("");
  const [moderators, setModerators] = useState<VTInvite[]>([]);
  const [attendees, setAttendees] = useState<VTInvite[]>([]);
  const [userIsAModerator, setUserIsAModerator] = useState<boolean>(false);

  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////
  /////////             RRule Strings
  /////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  const [existingActiveDaysString, setExistingActiveDaysString] =
    useState<string>("");
  const [existingByDay, setExistingByDay] = useState<any[]>([]);
  const [meetingStartDate, setMeetingStartDate] = useState<string>("");
  const [dayString, setDayString] = useState<string>("");
  const [monthString, setMonthString] = useState<string>("");
  const [yearString, setYearString] = useState<string>("");

  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////
  /////////             Global Store
  /////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  const { meetingDate, meetingUUID, globalDate } = meetingsStore();
  const { deleteModalOpen, setDeleteModalOpen, deleteType, setDeleteType } =
    utilityStore();

  const handleDeleteModal = (type: string) => {
    setDeleteModalOpen(true);
    setDeleteType(type);
  };

  const handleClose = () => {
    setMeetingOverViewModalIsOpen(false);
  };

  const handleEdit = () => {
    setEditMeetingModalIsOpen(true);
  };

  const isAllDay =
    (beginningTime === "0:00" || beginningTime === "00:00") &&
    (endingTime === "23:30" ||
      endingTime === "23:00" ||
      endingTime === "23:55");

  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////
  /////////             Get Single Meeting Information
  /////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const getSingleMeetingInfo = async () => {
      getMeeting(meetingUUID)
        .then((meeting: VTMeetings) => {
          setMeeting(meeting);
          setMeetingIsLoaded(true);
          meeting.external_id != null
            ? setIsOutlookGenerated(true)
            : setIsOutlookGenerated(false);
        })
        .catch((err: Error) => {
          console.log(err);
        });
    };

    getSingleMeetingInfo().then(() => {
      setNeedsRefresh(false);
    });
  }, [meetingUUID, needsRefresh, editMeetingModalIsOpen]);

  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////
  /////////             Get User Data
  /////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getCurrentUserInfo()
      .then((user: CognitoUser) => {
        setUserId(user.attributes.sub);
      })
      .catch((err: Error) => {});
  }, [meeting]);

  //Any variables that need to be loaded before the component is mounted
  useEffect(() => {
    if (Object.keys(meeting).length) {
      const durationDate = new Date(meeting.start * 1000);

      const hours = durationDate.getHours();
      const minutes = "0" + durationDate.getMinutes();
      const beginningTime = hours + ":" + minutes.substr(-2);
      const endingTime = new Date(
        durationDate.getTime() + meeting?.duration * 1000
      );

      const hoursEnd = endingTime.getHours();
      const hoursMinutes = "0" + endingTime.getMinutes();
      const endingTimeFormat = hoursEnd + ":" + hoursMinutes.substr(-2);

      const formattedDate = () => {
        if (meeting.rrule === "") {
          return format(durationDate, "dd MMMMMM, yyyy");
        } else {
          return format(
            fromUnixTime(parseInt(meetingDate as string)),
            "dd MMMMMM, yyyy"
          );
        }
      };

      setBeginningTime(beginningTime);
      setEndingTime(endingTimeFormat);
      setFormattedDate(formattedDate);
    }
  }, [meeting]);

  useEffect(() => {
    if (Object.keys(meeting).length) {
      meeting.invites.filter((invite: VTInvite) => {
        if (invite.invitee_id === userId) {
          setUserStatus(invite.status);
        }
      });
    }
    // console.log(meeting);
  }, [meeting, userId]);

  useEffect(() => {
    if (meeting.invites) {
      const inviteList = meeting.invites;
      const moderators = inviteList.filter(
        (invite: VTInvite) => invite.moderator === true
      );
      const attendees = inviteList.filter(
        (invite: VTInvite) => invite.moderator === false
      );

      inviteList.filter((invite: VTInvite) => {
        invite.invitee_id === userId ? setInviteId(invite.invite_id) : null;
      });

      setModerators(moderators);
      setAttendees(attendees);
    }
  }, [meeting, userId]);

  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////
  /////////             Checking If User Is a Moderator
  /////////
  /////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const userIsModerator = moderators.filter(
      (moderator: VTInvite) => userId === moderator.invitee_id
    )[0];
    if (userIsModerator && userIsModerator.moderator === true) {
      setUserIsAModerator(true);
    } else {
      setUserIsAModerator(false);
    }
  }, [moderators]);

  useEffect(() => {
    const daysArray = [
      {
        id: 1,
        day: "M",
        value: RRule.MO,
        string: "MO",
        fullDay: "Monday",
      },
      {
        id: 2,
        day: "T",
        value: RRule.TU,
        string: "TU",
        fullDay: "Tuesday",
      },
      {
        id: 3,
        day: "W",
        string: "WE",
        value: RRule.WE,
        fullDay: "Wednesday",
      },
      {
        id: 4,
        day: "T",
        value: RRule.TH,
        string: "TH",
        fullDay: "Thursday",
      },
      {
        id: 5,
        day: "F",
        string: "FR",
        value: RRule.FR,
        fullDay: "Friday",
      },
      {
        id: 6,
        day: "S",
        string: "SA",
        value: RRule.SA,
        fullDay: "Saturday",
      },
      {
        id: 7,
        day: "S",
        string: "SU",
        value: RRule.SU,
        fullDay: "Sunday",
      },
    ];

    if (meeting.rrule && editMeetingModalIsOpen) {
      const freq = meeting.rrule.split("Z")[1].split(";")[0].split("=")[1];

      if (freq !== "no") {
        const days = meeting.rrule.split("Z")[1].split(";")[3].split("=")[1];

        if (days.length > 2) {
          const newDaysArray = days.split(",");
          // map to set the existing active days string
          const newDays = daysArray
            .map((day: any) => {
              if (newDaysArray.includes(day.string)) {
                return day.fullDay;
              }
            })
            .filter((day: any) => day !== undefined);

          setExistingActiveDaysString(newDays.join(" and "));

          // a map to set existing by Day
          const newDaysMap = daysArray
            .map((day: any) => {
              if (newDaysArray.includes(day.string)) {
                return day.value;
              }
            })
            .filter((day: any) => day !== undefined);

          setExistingByDay(newDaysMap);
        } else {
          const newDaysArray = Array(days);

          const newDays = daysArray
            .map((day) => {
              if (newDaysArray.includes(day.string)) {
                return day.fullDay;
              }
            })
            .filter((day: any) => day !== undefined);

          setExistingActiveDaysString(newDays.toString());

          const newDaysMap = daysArray
            .map((day: any) => {
              if (newDaysArray.includes(day.string)) {
                return day.value;
              }
            })
            .filter((day: any) => day !== undefined);
          setExistingByDay(newDaysMap);
        }
      }
    }
  }, [meeting.rrule, meeting]);

  useEffect(() => {
    const day = meetingStartDate.slice(8);
    const getNumberWithOrdinal = () => {
      const endings = ["th", "st", "nd", "rd"];
      if (day) {
        const ending =
          endings[parseInt(day) % 100 > 3 ? 0 : parseInt(day) % 10];
        setDayString(`${day}${ending}`);
      }
    };
    getNumberWithOrdinal();
  }, [meetingStartDate, dayString, editMeetingModalIsOpen]);

  useEffect(() => {
    if (meeting.start) {
      const existingStartDate = format(
        new Date(meeting.start * 1000),
        "yyyy-MMMM-do"
      );
      const day = existingStartDate.split("-")[2];
      const year = existingStartDate.split("-")[0];
      const month = existingStartDate.split("-")[1];
      if (day) {
        setDayString(day);
      }
      if (month) {
        setMonthString(month);
      }
      if (year) {
        setYearString(year);
      }
    }
  }, [meeting]);

  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////
  /////////             Making sure these states are loaded before page is rendered
  /////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (
      Object.keys(meeting).length &&
      beginningTime &&
      endingTime &&
      formattedDate &&
      inviteId !== null &&
      userStatus &&
      meetingDate &&
      meetingUUID
    ) {
      setMeetingIsLoaded(true);
    }
  }, [
    meeting,
    beginningTime,
    endingTime,
    formattedDate,
    inviteId,
    userStatus,
    meetingDate,
    meetingUUID,
  ]);

  return (
    <>
      {meetingIsLoaded ? (
        <Modal open={meetingOverViewModalIsOpen} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute" as "absolute",
              right: 0,
              width: { xs: "100%", md: "75%", lg: "65%", xl: "40%" },
              height: "100%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              overflow: "hidden",
              zIndex: "10",
            }}
          >
            <Box
              sx={{ display: "flex", paddingLeft: "20px", paddingTop: "20px" }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "32.15px",
                  color: "#000000",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  hyphens: "auto",
                  width: "100%",
                }}
              >
                {meeting.name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {userIsAModerator === true ? (
                  <Box sx={{ paddingRight: "10px" }}>
                    <Button disabled={isOutlookGenerated} onClick={handleEdit}>
                      <EditIcon />
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
                <Button onClick={handleClose}>
                  <CloseIcon sx={{ color: "#000000" }} />
                </Button>
              </Box>
            </Box>
            {/**Start of MeetingInformation container   */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Stack
                p={1}
                spacing={2}
                sx={{
                  paddingLeft: "20px",
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "12.5px",
                    width: "auto",
                  }}
                >
                  <DateRangeIcon />
                  <Typography
                    sx={{ paddingLeft: "10px", paddingRight: "15px" }}
                  >
                    {formattedDate}
                  </Typography>

                  <AccessTimeIcon />
                  {isAllDay ? (
                    <>
                      <Typography sx={{ paddingLeft: "10px" }}>
                        All Day
                      </Typography>
                    </>
                  ) : (
                    <Typography sx={{ paddingLeft: "10px" }}>
                      {beginningTime} - {endingTime}
                    </Typography>
                  )}
                </Box>

                {meeting.rrule_description ? (
                  <>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <RepeatIcon />
                      <Typography>
                        Occurs every {dayString}, starting {dayString}{" "}
                        {monthString} {yearString}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <></>
                )}

                {isOutlookGenerated ? (
                  <Stack
                    p={1}
                    sx={{
                      background: "rgba(18, 113, 238, 0.1)",
                      borderRadius: "2px",
                      width: "100%",
                    }}
                    spacing={1}
                    direction="row"
                  >
                    <InfoOutlinedIcon />
                    <Typography>Created in Outlook</Typography>
                  </Stack>
                ) : (
                  <></>
                )}

                <Box
                  sx={{
                    marginTop: "39px",
                    display: "flex",
                    alignItems: "center",
                    width: "auto",
                    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <Avatar
                    {...getOwnerInitials(
                      meeting.owner_first_name + " " + meeting.owner_last_name
                    )}
                  />
                  <Typography sx={{ paddingLeft: "10px" }}>
                    Invited by{" "}
                    {meeting.owner_first_name + " " + meeting.owner_last_name}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    marginTop: "15px",
                    display: "flex",
                    alignItems: "center",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    hyphens: "auto",
                    whiteSpace: "pre-wrap",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
                    paddingBottom: "10px",
                  }}
                >
                  <EventNoteIcon />
                  <Typography
                    sx={{
                      width: "65%",
                      paddingLeft: "10px",
                      overflowWrap: "break-word",
                      wordWrap: "break-word",
                      hyphens: "auto",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {meeting.description
                      ? meeting.description
                      : "No description"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    marginTop: "15px",
                    width: "154.88px",
                    height: "37px",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "100%%", paddingRight: "10px" }}>
                      <JoinMeetingButton meeting={meeting} />
                    </Box>
                    <Box>
                      <InviteStatusSelector
                        inviteId={inviteId}
                        meeting={meeting}
                        userId={userId}
                        setNeedsRefresh={setNeedsRefresh}
                      />
                    </Box>
                  </Box>
                </Box>

                {meeting.owner_id === userId || userIsAModerator ? (
                  <>
                    {meeting.rrule === "" || meeting.rrule === null ? (
                      <Stack
                        spacing={1}
                        direction="row"
                        sx={{ position: "absolute", bottom: "100px" }}
                      >
                        <Button
                          sx={{
                            position: "absolute",

                            width: "200px",
                          }}
                          disabled={isOutlookGenerated}
                          variant="contained"
                          color="error"
                          onClick={() => {
                            handleDeleteModal("individual");
                          }}
                        >
                          Delete
                        </Button>
                      </Stack>
                    ) : (
                      <Stack
                        spacing={1}
                        direction="row"
                        sx={{ position: "absolute", bottom: "100px" }}
                      >
                        <Button
                          sx={{
                            width: "200px",
                          }}
                          disabled={isOutlookGenerated}
                          variant="text"
                          color="error"
                          onClick={() => {
                            handleDeleteModal("occurance");
                          }}
                        >
                          Delete Occurance
                        </Button>

                        <Button
                          sx={{
                            width: "200px",
                          }}
                          disabled={isOutlookGenerated}
                          variant="contained"
                          color="error"
                          onClick={() => {
                            handleDeleteModal("series");
                          }}
                        >
                          Delete Series
                        </Button>
                      </Stack>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Stack>

              {/** Start of the attendees container */}
              <Box
                p={1}
                sx={{
                  display: "flex",
                  width: "30%",
                  height: "100%",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Stack
                  sx={{
                    width: "100%",
                    display: "flex",
                    maxHeight: "70vh",
                    height: "80%",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "0.6em",
                      marginRight: "20px",
                      marginTop: "20px",
                      backgroundColor: "#F1F1F1",
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-track": {
                      boxShadow: "1 1 1 6px #F1F1F1",
                      webkitBoxShadow: "1 1 1 6px #F1F1F1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#C4C4C4",
                      borderRadius: "10px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      marginTop: "10px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "700", fontSize: "18px" }}>
                      Oragnizer
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "10px",
                        width: "100%",
                      }}
                    >
                      <Avatar
                        {...getOwnerInitials(
                          meeting.owner_first_name +
                            " " +
                            meeting.owner_last_name
                        )}
                      />
                      <Typography
                        sx={{ paddingLeft: "10px", textOverflow: "ellipsis" }}
                      >
                        {meeting.owner_first_name +
                          " " +
                          meeting.owner_last_name}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ marginTop: "10px" }}>
                    <Typography sx={{ fontWeight: "700", fontSize: "18px" }}>
                      Moderators
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      {moderators.map((moderator: VTInvite) => (
                        <Box
                          key={moderator.invitee_id}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "10px",
                            width: "100%",
                          }}
                        >
                          <Avatar
                            {...getOwnerInitials(
                              moderator.invitee_first_name +
                                " " +
                                moderator.invitee_last_name
                            )}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "left",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "1em",
                                paddingLeft: "10px",
                                width: "100%",
                              }}
                            >
                              {moderator.invitee_first_name +
                                " " +
                                moderator.invitee_last_name}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "1em", paddingLeft: "10px" }}
                            >
                              {moderator.status === "tentative" ? (
                                <Typography>Tentative</Typography>
                              ) : moderator.status === "accepted" ? (
                                <Typography
                                  sx={{ fontSize: "1em", color: "#01B695" }}
                                >
                                  Accepted
                                </Typography>
                              ) : moderator.status === "rejected" ? (
                                <Typography
                                  sx={{ fontSize: "1em", color: "#EB1414" }}
                                >
                                  Rejected
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{ fontSize: "1em", paddingLeft: "10px" }}
                                >
                                  Not Responded
                                </Typography>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>

                    {attendees.length ? (
                      <>
                        <Box sx={{ marginTop: "10px" }}>
                          <Typography
                            sx={{ fontWeight: "700", fontSize: "18px" }}
                          >
                            Attendees
                          </Typography>
                          <Box
                            sx={{
                              width: "100%",
                            }}
                          >
                            {attendees.map((attendee: VTInvite) => (
                              <Box
                                key={attendee.invitee_id}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                  paddingTop: "5px",
                                  height: "100%",
                                }}
                              >
                                <Avatar
                                  {...getOwnerInitials(
                                    attendee.invitee_first_name +
                                      " " +
                                      attendee.invitee_last_name
                                  )}
                                />

                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "left",
                                  }}
                                >
                                  <Typography
                                    noWrap
                                    sx={{
                                      fontSize: ".85em",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    {attendee.invitee_first_name +
                                      " " +
                                      attendee.invitee_last_name}
                                  </Typography>
                                  <Box>
                                    {attendee.status === "tentative" ? (
                                      <Typography>Tentative</Typography>
                                    ) : attendee.status === "accepted" ? (
                                      <Typography
                                        sx={{
                                          fontSize: ".85em",
                                          color: "#01B695",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        Accepted
                                      </Typography>
                                    ) : attendee.status === "rejected" ? (
                                      <Typography
                                        sx={{
                                          fontSize: ".85em",
                                          color: "#EB1414",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        Rejected
                                      </Typography>
                                    ) : (
                                      <Typography
                                        sx={{
                                          fontSize: ".85em",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        Not Responded
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                  <DialogDelete
                    meeting={meeting}
                    setSnackBarIsOpenForDeleteMeeting={
                      setSnackBarIsOpenForDeleteMeeting
                    }
                    setMeetingOverViewModalIsOpen={
                      setMeetingOverViewModalIsOpen
                    }
                  />
                </Stack>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default MeetingOverview;

import VideoSelector from "../../Components/VideoPreviewPage/Components/VideoSelector";
import MicrophoneSelector from "../../Components/VideoPreviewPage/Components/MicrophoneSelector";
import SpeakerSelector from "../../Components/VideoPreviewPage/Components/SpeakerSelector";

//React
import { useState, useEffect, useRef } from "react";

//Global Store
import { meetingStore, userStore } from "@state/store";

//Material UI Style Components
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";

//Device Connecting Functions
import { getVideo, getDeviceList } from "@utilities/deviceConnection";

//Utilities
import { getOwnerInitials } from "@utilities/getOwnerInitials";

interface VTGeneral {
  isLoaded: boolean;
  selectedMicrophone: string;
  setSelectedMicrophone: (selectedMicrophone: string) => void;
}

const General = ({
  isLoaded,
  selectedMicrophone,
  setSelectedMicrophone,
}: VTGeneral) => {
  const {
    client,
    addInitialsScreenShares,
    removeInitialsScreenSharesByEmail,
    preCallModalIsOpen,
    setPreCallModalIsOpen,
    selectedMeeting,
    setSelectedMeeting,
    setVideoDevices,
    activeVideoStream,
    selectedVideoStream,
    videoDevices,
    setSelectedVideoStream,
    setActiveVideoStream,
    videoIsOn,
    setVideoIsOn,
    microphoneIsOn,
    setMicrophoneIsOn,
    removeLocalClientById,
    setMicrophones,
    setSelectedMicrophoneStream,
    selectedMicrophoneStream,
  } = meetingStore();
  const constraints = {
    audio: false,
    video: {
      deviceId: {
        exact: selectedVideoStream,
      },
      facingMode: "user",
      height: { min: 432, ideal: 720, max: 1080 },
      width: { min: 768, ideal: 1280, max: 1920 },
    },
    aspectRatio: 1.777777778,
  };

  const { user } = userStore();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [selectedDevice, setSelectedDevice] = useState<string>("");

  const video = document.getElementById("video");

  //Creates dropdown for Videos
  useEffect(() => {
    if (selectedDevice === "" || selectedDevice) {
      console.log("Obtaining Camera List");
      try {
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: false })
          .then((s) => {
            getDeviceList("videoinput").then((videoSources) => {
              setVideoDevices(videoSources);
              videoSources.map((source) => {
                if (source.deviceId.includes("default")) {
                  setSelectedDevice(source.deviceId);
                  setSelectedVideoStream(source.deviceId);
                } else {
                  setSelectedDevice(videoSources[0].deviceId);
                  setSelectedVideoStream(videoSources[0].deviceId);
                }
              });
            });
          });
      } catch (error) {
        console.log("this is the error in getting video devices", error);
      }
    }
  }, [selectedVideoStream, selectedDevice]);

  //Creates the dropdown for Microphones
  useEffect(() => {
    if (selectedMicrophone === "") {
      try {
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: false })
          .then((s) => {
            getDeviceList("audioinput").then((audioSources) => {
              setMicrophones(audioSources);
              audioSources.map((source) => {
                if (source.deviceId.includes("default")) {
                  setSelectedMicrophone(source.deviceId);
                  setSelectedMicrophoneStream(source.deviceId);
                  setMicrophoneIsOn(true);
                } else {
                  setSelectedMicrophone(audioSources[0].deviceId);
                  setSelectedMicrophoneStream(audioSources[0].deviceId);
                  setMicrophoneIsOn(true);
                }
              });
            });
          });
      } catch (error) {
        console.log("there is an error in getting microphones");
      }
    }
  }, [selectedMicrophoneStream, selectedMicrophone]);

  //useEffect for every new selectedVideoStream
  useEffect(() => {
    if (selectedVideoStream) {
      getVideo(constraints).then((stream) => {
        if (stream.stream) {
          setActiveVideoStream(stream.stream);
          setVideoIsOn(true);
          removeInitialsScreenSharesByEmail("undefined");
        } else {
          console.log("There is an error");
        }
      });
    }
  }, [selectedVideoStream]);

  // This useEffect is to make sure that only one mediaStream is running,
  // and then sets the activeVideoStream to the video
  useEffect(() => {
    if (activeVideoStream && isLoaded) {
      //@ts-ignore
      const mediaStream: MediaStream = video?.srcObject;
      const tracks = mediaStream?.getTracks();
      if (tracks) {
        tracks[0]?.stop();
      }

      try {
        if (videoRef.current !== null) {
          videoRef.current.srcObject = activeVideoStream;
        }
      } catch (error) {
        console.log("Loading Camera...");
      }
    }
  }, [activeVideoStream, selectedVideoStream, isLoaded]);

  const handleVideo = () => {
    setVideoIsOn(!videoIsOn);
    if (videoIsOn === true && activeVideoStream) {
      const tracks: MediaStreamTrack[] = activeVideoStream?.getVideoTracks();
      tracks[0].enabled = false;

      removeLocalClientById(user.user_id);
      addInitials();
    } else {
      getVideo(constraints).then((stream) => {
        if (stream.stream) {
          setActiveVideoStream(stream.stream);
          setVideoIsOn(true);
        } else {
          console.log("There is an error");
        }
      });
      removeInitialsScreenSharesByEmail(user.user_id);
    }
  };

  const addInitials = () => {
    const initials: { id: string; name: string; email: string } = {
      id: user.user_id,
      name: user.display_name,
      email: user.user_id,
    };

    addInitialsScreenShares(initials);
  };

  useEffect(() => {
    if (isLoaded && videoRef.current !== null) {
      videoRef.current.srcObject = activeVideoStream;
      videoRef.current.play();
    }
  }, [isLoaded, videoRef, videoIsOn]);

  return (
    <>
      <Stack
        flexDirection="row"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          paddingTop: "30px",
        }}
      >
        {isLoaded ? (
          <Box
            sx={{
              borderRadius: "10px",
              backgroundColor: "black",
              width: "768px",
              height: "432px",
              color: "black",
            }}
          >
            <>
              {videoIsOn ? (
                <video
                  id="video"
                  ref={(el) => {
                    videoRef.current = el;
                  }}
                  autoPlay
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      width: "100%",

                      alignItems: "center",
                      borderTop: "1px solid rgba(0, 0, 0, 0.05)",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <Avatar
                      {...getOwnerInitials(user.display_name)}
                      sx={{
                        height: "100px",
                        width: "100px",
                        fontSize: "3.25rem",
                      }}
                    />
                  </Box>
                </>
              )}
            </>
          </Box>
        ) : (
          <>
            <Skeleton
              variant="rectangular"
              height={432}
              width={768}
              sx={{ bgcolor: "grey.900" }}
            />
          </>
        )}

        {isLoaded ? (
          <Stack
            sx={{
              width: "350px",
              paddingLeft: "15px",
              justifyContent: "space-evenly",
            }}
          >
            <VideoSelector
              handleVideo={handleVideo}
              selectedDevice={selectedDevice}
              setSelectedDevice={setSelectedDevice}
            />

            <MicrophoneSelector
              isLoaded={isLoaded}
              selectedMicrophone={selectedMicrophone}
              setSelectedMicrophone={setSelectedMicrophone}
            />

            <SpeakerSelector />
          </Stack>
        ) : (
          <Stack sx={{ height: "100%", justifyContent: "space-evenly" }}>
            <Skeleton
              width={350}
              height={150}
              sx={{
                marginLeft: "15px",
                bgcolor: "grey.900",
              }}
            />
            <Skeleton
              width={350}
              height={150}
              sx={{
                marginLeft: "15px",
                bgcolor: "grey.900",
              }}
            />
            <Skeleton
              width={350}
              height={150}
              sx={{
                marginLeft: "15px",
                bgcolor: "grey.900",
              }}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default General;
